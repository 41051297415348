<template>
    <transition name="fade">
        <div v-if="showModalWindow" class="modal-window">
            <div :class="['modal-window__content', scrollClass]">
                <ModalClose :close-event="closeEvent" modal-class="modal-window__close"></ModalClose>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
import ModalClose from "../ModalClose";

export default {
    components: {ModalClose},
    props: {
        closeEvent: {
            type: String,
            required: true
        },
        showModalWindow: {
            type: Boolean,
            required: true,
        },
        scrollClass: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            closeClick: null
        }
    }
};
</script>
