var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showModalWindow
      ? _c("div", { staticClass: "modal-window" }, [
          _c(
            "div",
            { class: ["modal-window__content", _vm.scrollClass] },
            [
              _c("ModalClose", {
                attrs: {
                  "close-event": _vm.closeEvent,
                  "modal-class": "modal-window__close",
                },
              }),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }